<template>
  <div>
    <hs-form id="signIn_form_funnels" @submit="submit">
      <h5 class="auth-title">{{ $t('login.form.title') }}</h5>
      <p class="auth-subtitle">{{ $t('login.form.subtitle') }}</p>

      <b-form-group>
        <label>{{ $t('login.form.fields.email.name') }}</label>
        <hs-input
          id="email"
          type="email"
          @blur="$v.form.email.$touch()"
          v-model="form.email"
          :state="!$v.form.email.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.email.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.form.email.required">
                {{ $t('login.form.fields.email.validation.required') }}
              </span>
              <span v-if="!$v.form.email.email">
                {{ $t('login.form.fields.email.validation.type') }}
              </span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </b-form-group>

      <Captcha ref="captchaComponent" @verify="onVerify($event)" @error="onError" />

      <hs-button block class="auth-form-button" variant="primary" type="submit" :disabled="$v.form.$invalid || loading">
        <b-spinner variant="gray" v-if="loading" small />
        {{ $t('login.form.buttons.login') }}
      </hs-button>

      <p class="auth-footer-p">
        {{ $t('login.not_have_an_account') }}
        <router-link :to="signupUrl" data-test="plans-page-link">
          <u>{{ $t('login.join_our_waiting_list') }}</u>
        </router-link>
      </p>
    </hs-form>
  </div>
</template>

<script>
import { hsForm } from '@/components';
import { required, email } from 'vuelidate/lib/validators';
import Captcha from '@/components/Captcha/index.vue';

export default {
  components: {
    hsForm,
    Captcha,
  },
  data() {
    return {
      loading: false,
      form: {
        email: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    signupUrl() {
      return {
        name: 'SignUp',
        query: this.$route.query,
      };
    },
  },
  mounted() {
    this.fillQueryParamData();
  },
  methods: {
    submit() {
      this.loading = true;
      this.$refs.captchaComponent.execute();
    },
    onVerify(captcha_token) {
      this.loading = false;
      this.$emit('getSchoolsByEmail', this.form.email, captcha_token);
    },
    onError() {
      this.loading = false;
    },
    fillQueryParamData() {
      const emailQueryParam = this.$route.query.email;

      if (!emailQueryParam) return;

      setTimeout(() => {
        try {
          this.form.email = emailQueryParam;
          this.$v.form.email.$touch();
          if (!this.$v.form.email.$invalid) {
            this.submit();
          }
        } catch {
          this.loading = false;
        }
      }, 500);
    },
  },
};
</script>
