<template>
  <structure-auth :shape="require('@/assets/images/login/login_shape_vector.svg')">
    <hs-loading v-if="isLoading" />
    <template v-else>
      <StepEmail v-if="step === 1" @getSchoolsByEmail="getSchoolsByEmail" />
      <StepPassword v-else-if="step === 2" :user-email="form.email" @backToEmail="backToEmail" @login="loginUser" />
    </template>
  </structure-auth>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import StructureAuth from '@/auth/components/Auth';
import { hsLoading } from '@/components';
import StepEmail from './components/StepEmail';
import StepPassword from './components/StepPassword';
import ToastHelper from '@/shared/helpers/toast';
import { analyticsService } from '@/services';
import inviteService from '@/sparkaffiliates/services/invite';
import Features from '@/types/features';

export default {
  components: {
    hsLoading,
    StructureAuth,
    StepEmail,
    StepPassword,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      isLoading: false,
      step: 1,
    };
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
      schools: state => state.items,
    }),
  },
  methods: {
    ...mapMutations('school', ['addFeature']),
    ...mapGetters('school', ['hasFeature']),
    ...mapActions('school', ['getSchools']),
    ...mapGetters('auth', ['fullName']),
    ...mapActions('auth', ['login']),

    backToEmail(data) {
      this.step = 1;
      this.form.email = null;

      if (data && data.onlySchoolBlocked) {
        ToastHelper.dangerMessage(this.$t('login.form.toast.only_schools_blocked'));
      }
    },
    async getSchoolsByEmail(email, captcha_token) {
      try {
        this.form.email = email;
        this.isLoading = true;

        await this.getSchools({ email, captcha_token });
        this.isLoading = false;

        if (!this.schools.length) {
          ToastHelper.dangerMessage(this.$t('login.form.toast.no_schools'));

          this.$router.push({
            name: 'SignUp',
            query: this.$route.query,
          });

          return;
        }

        this.step++;
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('login.form.toast.request_error'));
        this.isLoading = false;
      }
    },

    identify() {
      analyticsService.identify(undefined, { lastLogin: new Date() });
      analyticsService.track({
        event: 'Signed In',
        props: {},
      });
    },

    async loginUser(payload) {
      try {
        this.isLoading = true;
        this.form = { ...this.form, password: payload.password, school: payload.school };

        await this.login(this.form);
        this.identify();
        this.isLoading = false;

        const routeName =
          this.$route.query.name ||
          (!payload.school.extra_settings.questionary_filled ? 'QuestionaryPage' : 'HomePage');

        let redirectTo = { ...this.$route, name: routeName };

        const invite = window.sessionStorage.getItem('invite-code');
        if (invite) {
          const { inviteCode, product } = JSON.parse(invite);

          await inviteService.acceptInvite(
            inviteCode,
            this.selectedSchool.account_type,
            this.selectedSchool.id,
            this.selectedSchool.email,
            this.selectedSchool.name
          );

          let enableFeature;

          if (product === 'sparkaffiliates') {
            enableFeature = Features.SPARK_AFFILIATES;
            redirectTo = { ...this.$route, name: 'MyAffiliations' };
          } else if (product === 'coproduction') {
            enableFeature = Features.COPRODUCTOR;
            redirectTo = { ...this.$route, name: 'ProductsList', params: { goToCoprodutions: 'true' } };
          }

          enableFeature && this.addFeature(enableFeature);

          window.sessionStorage.removeItem('invite-code');
        }

        this.$router.push(redirectTo);
      } catch (e) {
        ToastHelper.dangerMessage(this.$t('login.form.toast.error'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
