<template>
  <section
    class="tlw-flex auth-page"
    :style="{ backgroundImage: `url(${illustration}), url(${shape})`, ...authStyles }"
  >
    <div class="auth-content-wrapper lg:tlw-w-6/12 tlw-flex tlw-flex-col tlw-min-h-full" :class="wrapperClass">
      <figure class="mb-4">
        <hs-logo height="25" color="#FF305C" />
      </figure>
      <div class="auth-slot-container tlw-flex tlw-flex-col tlw-justify-center tlw-w-full">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import hsLogo from '@/components/Logo';

export default {
  components: {
    hsLogo,
  },
  props: {
    shape: {
      type: String,
    },
    illustration: {
      type: String,
    },
    wrapperClass: String,
    authStyles: {
      type: Object,
      default: () => ({}),
    },
  },
  name: 'structure-auth',
};
</script>

<style lang="scss">
.auth-page {
  background-position: 100% 50%, bottom right;
  background-repeat: no-repeat;
  background-size: 50% 70%, 55% auto;
  min-height: 100vh;

  @media (max-width: $screen-sm), (max-width: $screen-md) {
    background-image: none !important;
  }
}
.u-opacity-0 {
  opacity: 0;
}

.auth-content-wrapper {
  padding: 2% 10%;

  @media screen and (max-width: $screen-md), screen and (max-width: $screen-sm) {
    width: 100%;
    padding: 5% 10%;
  }
}

.auth-slot-container {
  // so the content can be vertically centered
  // we have to calc the view height minus the parent padding and the logo total size
  min-height: calc(100vh - (100vw * 0.04) - 26px - 1.5rem);

  @media screen and (max-width: $screen-md), screen and (max-width: $screen-sm) {
    min-height: calc(100vh - (100vw * 0.1) - 26px - 1.5rem);
  }
}

.auth-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 115%;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}

.auth-subtitle {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 3rem;
}

.auth-form-button {
  margin: 1.5rem 0 3rem 0;
}

.auth-footer-p {
  font-size: 0.85rem;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
}
</style>
