<template>
  <hs-form id="signIn_form_funnels" @submit="login">
    <h5 class="auth-title">{{ $t('login.form.title') }}</h5>
    <p class="auth-subtitle" v-if="!form.school">{{ $t('login.form.subtitle') }}</p>
    <p class="auth-subtitle" v-else-if="form.school">{{ $t('login.form.subtitle_selected_school') }}</p>
    <hs-group>
      <label for="email">{{ $t('login.form.fields.email.name') }}</label>
      <hs-input id="email" type="email" v-model="userEmail" :state="null" readonly disabled />
    </hs-group>
    <hs-group v-if="schools.length > 1">
      <label for="select-school">{{ $t('login.form.fields.password.select-school') }}</label>
      <hs-multiselect
        id="select-school"
        v-model="form.school"
        track-by="name"
        label="name"
        :placeholder="$t('login.form.fields.password.select.placeholder')"
        :options="schools"
        :searchable="false"
        :allow-empty="false"
        :show-labels="false"
        :loading="isLoading"
        :options-limit="100"
        @search-change="searchSchools"
      >
        <span slot="noResult">{{ $t('login.form.fields.password.select.no_results') }}</span>
      </hs-multiselect>
      <template v-if="!$v.form.school.$error">
        <hs-form-invalid-feedback :state="false" v-if="!$v.form.school.required">
          {{ $t('login.form.fields.password.validation.required') }}
        </hs-form-invalid-feedback>
      </template>
    </hs-group>
    <hs-group v-if="form.school || schools.length === 1">
      <label>{{ $t('login.form.fields.password.name') }}</label>
      <hs-input
        id="password"
        type="password"
        @blur="$v.form.password.$touch()"
        v-model="form.password"
        :state="!$v.form.password.$error ? null : false"
      >
        <template slot="feedback" v-if="$v.form.password.$error">
          <hs-form-invalid-feedback :state="false" v-if="!$v.form.password.required">
            {{ $t('login.form.fields.password.validation.required') }}
          </hs-form-invalid-feedback>
        </template>
      </hs-input>
      <router-link class="forgot-password font-weight-bold" :to="{ name: 'RecoverPasswordEmail' }">{{
        $t('login.form.buttons.forgot_password')
      }}</router-link>
    </hs-group>

    <hs-button block class="auth-form-button" variant="primary" type="submit" :disabled="$v.form.$invalid">
      {{ $t('login.form.buttons.login') }}
    </hs-button>
    <p class="auth-footer-p">
      {{ $t('login.not_have_an_account') }}
      <a data-test="plans-page-link" href="https://app.herospark.com/sign-up" title="Nossos Planos">
        <u>{{ $t('login.join_our_waiting_list') }}</u>
      </a>
    </p>
  </hs-form>
</template>

<script>
import { hsForm } from '@/components';
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import debounce from 'lodash.debounce';

export default {
  components: {
    hsForm,
  },
  data() {
    return {
      isLoading: false,
      form: {
        password: null,
        school: null,
      },
    };
  },
  props: {
    userEmail: String,
  },
  computed: {
    ...mapState('school', {
      schools: state => state.items,
    }),
    isAdminEmail() {
      return this.userEmail === this.$t('login.form.fields.email.admin');
    },
  },
  validations: {
    form: {
      password: {
        required,
      },
      school: {
        required,
      },
    },
  },
  methods: {
    ...mapActions('school', ['getSchoolsByName']),
    backToEmail() {
      this.$emit('backToEmail');
    },
    login() {
      this.$emit('login', this.form);
    },
    searchSchools(name = '') {
      this.isLoading = true;
      this.debouncedSearchSchools(name);
    },
  },
  created() {
    if (this.schools.length === 1) {
      if (this.schools[0].status === 'blocked') {
        this.$emit('backToEmail', { onlySchoolBlocked: true });
        return;
      }

      this.form.school = this.schools[0];
      return;
    }

    this.debouncedSearchSchools = debounce(name => {
      this.isLoading = true;
      this.getSchoolsByName({ name })
        .then(schools => {
          this.schools = schools.filter(school => school.status !== 'blocked');
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  color: $grey-dark;
  font-size: 13px;
}
</style>
